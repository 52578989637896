import { ComponentRef, FC, useCallback, useRef, useState } from 'react';
import { PeriodicReviewConfig, PeriodicReviewChecklistItem } from '../../../models/Form';
import { useTranslation } from 'react-i18next';
import { usePeriodicReviewWizard } from '../../../contexts/PeriodicReviewContext';
import TranslatableInput from '../form-control/TranslatableInput';
import TrashIcon from '../icon/TrashIcon';
import { v4 as uuid } from 'uuid';
import PlusIcon from '../icon/PlusIcon';
import Button, { ButtonType, ButtonSize } from '../form-control/Button';
import { ModalContext } from '../../../contexts/ModalContext';
import ConfirmationModal from '../modal/variants/ConfirmationModal';

type Props = {
  onChange: (value: PeriodicReviewConfig) => void;
};

const ReviewCheckList: FC<Props> = ({ onChange }) => {
  const { config } = usePeriodicReviewWizard();
  const { t } = useTranslation('periodic-review');
  const [showRemoveCheckListModal, setShowRemoveCheckListModal] = useState(false);

  const onAddCheckListItem = useCallback(() => {
    onChange({ ...config, checkList: [...(config.checkList || []), { id: uuid(), translations: {} }] });
  }, [config, onChange]);

  const onItemChange = useCallback(
    (item: PeriodicReviewChecklistItem) => {
      onChange({ ...config, checkList: config.checkList?.map((checkListItem) => (checkListItem.id === item.id ? item : checkListItem)) });
    },
    [config, onChange],
  );

  const onItemDelete = useCallback(
    (item: PeriodicReviewChecklistItem) => {
      onChange({ ...config, checkList: config.checkList?.filter((checkListItem) => checkListItem.id !== item.id) });
    },
    [config, onChange],
  );

  const onCheckListDelete = useCallback(() => {
    onChange({ ...config, checkList: [] });
  }, [config, onChange]);

  return (
    <div>
      {config.checkList?.map((item) => <CheckListItem item={item} key={item.id} onChange={onItemChange} onDelete={() => onItemDelete(item)} />)}
      <div className="sticky bottom-0 flex justify-between bg-white">
        <Button type={ButtonType.TERTIARY} size={ButtonSize.S} onClick={onAddCheckListItem} data-cy="add-checklist-item-button">
          <Button.Slot name="Icon">
            <PlusIcon className="h-3 w-3" />
          </Button.Slot>
          {t('checklist.add-item')}
        </Button>
        <Button type={ButtonType.TERTIARY} size={ButtonSize.S} onClick={() => setShowRemoveCheckListModal(true)} data-cy="delete-checklist-button">
          {t('checklist.delete')}
        </Button>
      </div>
      <ModalContext.Provider value={{ open: showRemoveCheckListModal, modalWidth: 'w-2/5', onClose: () => setShowRemoveCheckListModal(false) }}>
        <ConfirmationModal
          onConfirm={onCheckListDelete}
          onCancel={() => setShowRemoveCheckListModal(false)}
          title={t('modals.delete-all-checklist.title')}
          description={t('modals.delete-all-checklist.description')}
          confirmText={t('modals.delete-all-checklist.buttons.confirm')}
        />
      </ModalContext.Provider>
    </div>
  );
};

export default ReviewCheckList;

type ChecListItemProps = {
  item: PeriodicReviewChecklistItem;
  onChange: (item: PeriodicReviewChecklistItem) => void;
  onDelete: () => void;
};

const CheckListItem: FC<ChecListItemProps> = (props) => {
  const { item, onChange, onDelete } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const translatableInputRef = useRef<ComponentRef<typeof TranslatableInput>>(null);

  const onItemChange = useCallback(
    <T extends keyof typeof item>(key: T, value: (typeof item)[T]) => {
      onChange({ ...item, [key]: value });
    },
    [item, onChange],
  );

  return (
    <div className="mb-4 flex items-center gap-2">
      <TranslatableInput
        ref={translatableInputRef}
        innerRef={inputRef}
        translations={item.translations || {}}
        translationKey="text"
        onTranslationsChange={(value) => onItemChange('translations', value)}
        data-cy="checklist-item-manage"
        autoFocus
      />
      <div className="flex-shrink-0">
        <TrashIcon data-cy="delete-checklist-item-button" className="h-6 w-6" onClick={onDelete} />
      </div>
    </div>
  );
};
