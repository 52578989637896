import { ComponentRef, FC, useEffect, useRef } from 'react';
import { usePeriodicReviewWizard } from '../../../contexts/PeriodicReviewContext';
import { PeriodicReviewConfig } from '../../../models/Form';
import { useTranslation } from 'react-i18next';
import TranslatableInput from '../form-control/TranslatableInput';

type Props = {
  onChange: (value: PeriodicReviewConfig) => void;
};

const ReviewGoal: FC<Props> = ({ onChange }) => {
  const { config } = usePeriodicReviewWizard();
  const { t } = useTranslation('periodic-review');
  const goalTextAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const translatableGoalInputRef = useRef<ComponentRef<typeof TranslatableInput>>(null);
  const repercussionsTextAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const translatableRepercussionsInputRef = useRef<ComponentRef<typeof TranslatableInput>>(null);

  useEffect(() => {
    goalTextAreaRef.current?.focus();
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <div>
        <TranslatableInput
          inputType="multiline"
          className="resize-none"
          ref={translatableGoalInputRef}
          innerRef={goalTextAreaRef}
          label={t('goal-title')}
          placeholder={t('goal-title')}
          translations={config.translations || {}}
          translationKey="goal"
          onTranslationsChange={(value) => onChange({ ...config, translations: value })}
          data-cy="periodic-review-goal"
          maxLength={1000}
        />
      </div>
      <div>
        <TranslatableInput
          inputType="multiline"
          className="resize-none"
          ref={translatableRepercussionsInputRef}
          innerRef={repercussionsTextAreaRef}
          label={t('repercussions-title')}
          placeholder={t('repercussions-title')}
          translations={config.translations || {}}
          translationKey="repercussions"
          onTranslationsChange={(value) => onChange({ ...config, translations: value })}
          data-cy="periodic-review-repercussions"
          maxLength={1000}
        />
      </div>
    </div>
  );
};

export default ReviewGoal;
