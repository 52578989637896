import React, { useMemo } from 'react';
import StandardModal from '../modal/variants/StandardModal';
import ReviewCheckList from './ReviewCheckList';
import { usePeriodicReviewWizard } from '../../../contexts/PeriodicReviewContext';
import { useTranslation } from 'react-i18next';
import WizardStepsIndicator from '../wizard/WizardStepsIndicator';
import EmptyState from '../placeholder/EmptyState';
import Button, { ButtonType } from '../form-control/Button';
import PlusIcon from '../icon/PlusIcon';
import { v4 as uuid } from 'uuid';

const ReviewCheckListStep = () => {
  const { config, setConfig, prevStep, stepNames, nextStep, save, wizardTitle, state, remove } = usePeriodicReviewWizard();
  const { t } = useTranslation('periodic-review');

  const hasChecklist = useMemo(() => config.checkList && config.checkList?.length > 0, [config.checkList]);

  return (
    <StandardModal
      title={wizardTitle}
      onCancelClick={prevStep}
      cancelButtonTitle={t('wizard.buttons.back')}
      confirmButtonTitle={state === 'set' || state === 'override' ? t('wizard.buttons.save') : t('wizard.buttons.update')}
      onConfirmClick={() => save && save(config)}
      tertiaryButtonTitle={t('wizard.buttons.delete')}
      tertiaryButtonIcon={null}
      onTertiaryButtonClick={remove}
    >
      {stepNames && nextStep && <WizardStepsIndicator activeStepIndex={2} stepNames={stepNames} onStepChange={nextStep} />}
      {hasChecklist && <div>{t('checklist.description')}</div>}
      <div className="h-[60vh] flex-grow overflow-auto px-1 pt-4">
        {hasChecklist && <ReviewCheckList onChange={(config) => setConfig && setConfig(config)} />}
        {!hasChecklist && (
          <div className="flex h-full items-center justify-center">
            <EmptyState title={t('checklist.empty-title')} description={t('checklist.description')} icon="CheckIcon">
              <Button type={ButtonType.TERTIARY} onClick={() => setConfig && setConfig({ ...config, checkList: [{ id: uuid(), translations: {} }] })}>
                <Button.Slot name="Icon">
                  <PlusIcon className="h-3 w-3" />
                </Button.Slot>
                {t('checklist.toggle')}
              </Button>
            </EmptyState>
          </div>
        )}
      </div>
    </StandardModal>
  );
};

export default ReviewCheckListStep;
