import { FC } from 'react';
import { DefaultDownloadPdfPreferences, DownloadPdfPreferences } from '../../models/DownloadPdfPreferences';
import Checkbox, { SliderSize } from '../shared/form-control/Checkbox';
import { useTranslation } from 'react-i18next';

type Props = {
  preferences?: DownloadPdfPreferences | null;
  onPreferenceChange: (key: string, value: boolean) => void;
};
const PdfPreferences: FC<Props> = (props) => {
  const { preferences = DefaultDownloadPdfPreferences, onPreferenceChange } = props;
  const { t } = useTranslation(['form']);
  return (
    <div>
      {Object.entries(preferences || DefaultDownloadPdfPreferences).map(([key, value]) => (
        <div key={key} className="bg-white" data-cy={`pdf-preference-${key}`}>
          <div className={`flex w-full items-center`}>
            <div className="flex w-full items-center justify-between p-2">
              <div className="flex flex-col">
                <div className="font-medium">{t(`form:pdf-download-pref.${key}.title`) as string}</div>
                <div className="text-dpm-12">{t(`form:pdf-download-pref.${key}.description`) as string}</div>
              </div>
              <div>
                <Checkbox value={value} onChange={(selectedValue) => onPreferenceChange(key, selectedValue)} slider sliderSize={SliderSize.S} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PdfPreferences;
