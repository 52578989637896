import { useTranslation } from 'react-i18next';
import { usePeriodicReviewWizard } from '../../../contexts/PeriodicReviewContext';
import StandardModal from '../modal/variants/StandardModal';
import ReviewConfig from './ReviewConfig';
import WizardStepsIndicator from '../wizard/WizardStepsIndicator';
import { useMemo } from 'react';
import { PeriodicReviewUtils } from '../../../utils/PeriodicReviewUtils';

const ReviewConfigStep = () => {
  const { config, setConfig, prevStep, nextStep, stepNames, wizardTitle, remove } = usePeriodicReviewWizard();
  const { t } = useTranslation('periodic-review');

  const validationErrors = useMemo(() => PeriodicReviewUtils.validate(config, t), [config, t]);

  return (
    <StandardModal
      title={wizardTitle}
      onCancelClick={prevStep}
      cancelButtonTitle={t('wizard.buttons.back')}
      confirmButtonTitle={t('wizard.buttons.next')}
      confirmDisabled={validationErrors.length > 0}
      onConfirmClick={() => nextStep && nextStep('checklist')}
      tertiaryButtonTitle={t('wizard.buttons.delete')}
      tertiaryButtonIcon={null}
      onTertiaryButtonClick={remove}
    >
      {stepNames && nextStep && <WizardStepsIndicator activeStepIndex={1} stepNames={stepNames} onStepChange={nextStep} />}
      <div className="h-[60vh] flex-grow overflow-auto px-1">
        <ReviewConfig onChange={(config) => setConfig && setConfig(config)} />
      </div>
    </StandardModal>
  );
};

export default ReviewConfigStep;
