import StandardModal from '../modal/variants/StandardModal';
import { useTranslation } from 'react-i18next';
import { usePeriodicReviewWizard } from '../../../contexts/PeriodicReviewContext';
import ReviewGoal from './ReviewGoal';
import WizardStepsIndicator from '../wizard/WizardStepsIndicator';

const ReviewGoalStep = () => {
  const { setConfig, cancel, nextStep, stepNames, wizardTitle, remove } = usePeriodicReviewWizard();
  const { t } = useTranslation('periodic-review');
  return (
    <StandardModal
      title={wizardTitle}
      onCancelClick={cancel}
      confirmButtonTitle={t('wizard.buttons.next')}
      onConfirmClick={() => nextStep && nextStep('config')}
      tertiaryButtonIcon={null}
      tertiaryButtonTitle={t('wizard.buttons.delete')}
      onTertiaryButtonClick={remove}
    >
      {stepNames && nextStep && <WizardStepsIndicator activeStepIndex={0} stepNames={stepNames} onStepChange={nextStep} />}
      <div className="h-[60vh] flex-grow overflow-auto px-1">
        <ReviewGoal onChange={(config) => setConfig && setConfig(config)} />
      </div>
    </StandardModal>
  );
};

export default ReviewGoalStep;
