import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePeriodicReviewWizard } from '../../../contexts/PeriodicReviewContext';
import { PeriodicReviewConfig } from '../../../models/Form';
import RadioButton from '../form-control/RadioButton';
import { DatePicker, DatePickerType } from '../form-control/DatePicker';
import { ButtonType } from '../form-control/Button';
import CalendarIcon from '../icon/CalendarIcon';
import DateUtils from '../../../utils/DateUtils';
import DropdownSelect from '../form-control/DropdownSelect';
import { Input } from '../form-control/Input';
import { DateInterval, DateIntervalKeys } from '../../../models/DateInterval';
import InfoIcon from '../icon/InfoIcon';
import Tooltip from '../Tooltip';
import i18next from 'i18next';

type Props = {
  onChange: (value: PeriodicReviewConfig) => void;
};

const ReviewConfig: FC<Props> = ({ onChange }) => {
  const { config } = usePeriodicReviewWizard();
  const { t } = useTranslation(['periodic-review', 'common']);
  const intervals = DateUtils.dateIntervalOptions(t);

  // #region Due date
  const [isFixedDueDate, setIsFixedDueDate] = useState(!!config.initialReviewDate || (!config.initialReviewAmount && !config.initialReviewDate));
  const [isAfterCompletion, setIsAfterCompletion] = useState(config.initialReviewAmount > 0);
  const selectedinItialReviewIntervalOption = useMemo(
    () => ({
      id: (config.initialReviewInterval ?? DateInterval.MONTH).toString(),
      value: config.initialReviewInterval ?? DateInterval.MONTH,
      text: t(DateIntervalKeys[config.initialReviewInterval ?? DateInterval.MONTH]),
    }),
    [config.initialReviewInterval, t],
  );
  const onIsFixedDueDateChange = useCallback(
    (value: boolean) => {
      if (value) {
        setIsFixedDueDate(value);
        setIsAfterCompletion(!value);
        onChange({ ...config, initialReviewAmount: 0, initialReviewInterval: DateInterval.MONTH });
      }
    },
    [config, onChange],
  );
  const onIsAfterCompletionChange = useCallback(
    (value: boolean) => {
      if (value) {
        setIsAfterCompletion(value);
        setIsFixedDueDate(!value);
        onChange({ ...config, initialReviewDate: undefined, initialReviewAmount: 1, initialReviewInterval: DateInterval.MONTH });
      }
    },
    [config, onChange],
  );
  // #endregion

  // #region Frequency of recurrence
  const [isRecurringOnce, setIsRecurringOnce] = useState(!config.recurrenceFrequencyAmount);
  const [isRecurringMultiple, setIsRecurringMultiple] = useState(config.recurrenceFrequencyAmount > 0);
  const selectedMultipleRecurrenceOption = useMemo(
    () => ({
      id: (config.recurrenceFrequencyInterval ?? DateInterval.MONTH).toString(),
      value: config.recurrenceFrequencyInterval ?? DateInterval.MONTH,
      text: t(DateIntervalKeys[config.recurrenceFrequencyInterval ?? DateInterval.MONTH]),
    }),
    [config.recurrenceFrequencyInterval, t],
  );
  const onIsRecurringOnceChange = useCallback(
    (value: boolean) => {
      if (value) {
        setIsRecurringOnce(value);
        setIsRecurringEndNever(value);
        setIsRecurringEndOn(!value);
        setIsRecurringEndAfter(!value);
        setIsRecurringMultiple(!value);
        onChange({
          ...config,
          recurrenceFrequencyAmount: 0,
          recurrenceFrequencyInterval: DateInterval.MONTH,
          recurrenceEndFixedDate: undefined,
          recurrenceEndOccurrences: 0,
        });
      }
    },
    [config, onChange],
  );
  const onIsRecurringMultipleChange = useCallback(
    (value: boolean) => {
      if (value) {
        setIsRecurringMultiple(value);
        setIsRecurringOnce(!value);
        setIsRecurringEndNever(value);
        onChange({ ...config, recurrenceFrequencyAmount: 1, recurrenceFrequencyInterval: DateInterval.MONTH });
      }
    },
    [config, onChange],
  );
  const onRecurrenceFrequencyAmountChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(event.target.value);
      const isOnceOff = value <= 0;
      if (isOnceOff) {
        setIsRecurringEndNever(true);
        setIsRecurringEndAfter(false);
        setIsRecurringEndOn(false);
      }
      onChange({
        ...config,
        recurrenceFrequencyAmount: value,
        recurrenceEndFixedDate: isOnceOff ? undefined : config.recurrenceEndFixedDate,
        recurrenceEndOccurrences: isOnceOff ? 0 : config.recurrenceEndOccurrences,
      });
    },
    [config, onChange],
  );
  // #endregion

  // #region Recurrence end
  const [isRecurringEndNever, setIsRecurringEndNever] = useState(
    config.recurrenceFrequencyAmount > 0 && !config.recurrenceEndFixedDate && !config.recurrenceEndOccurrences,
  );
  const [isRecurringEndOn, setIsRecurringEndOn] = useState(!!config.recurrenceEndFixedDate);
  const [isRecurringEndAfter, setIsRecurringEndAfter] = useState(config.recurrenceEndOccurrences > 0);
  const onIsRecurringEndNeverChange = useCallback(
    (value: boolean) => {
      if (value) {
        setIsRecurringEndNever(value);
        setIsRecurringEndOn(!value);
        setIsRecurringEndAfter(!value);
        onChange({ ...config, recurrenceEndFixedDate: undefined, recurrenceEndOccurrences: 0 });
      }
    },
    [config, onChange],
  );
  const onIsRecurringEndOnChange = useCallback(
    (value: boolean) => {
      if (value) {
        setIsRecurringEndOn(value);
        setIsRecurringEndNever(!value);
        setIsRecurringEndAfter(!value);
        onChange({ ...config, recurrenceEndOccurrences: 0 });
      }
    },
    [config, onChange],
  );
  const onIsRecurringEndAfterChange = useCallback(
    (value: boolean) => {
      if (value) {
        setIsRecurringEndAfter(value);
        setIsRecurringEndOn(!value);
        setIsRecurringEndNever(!value);
        onChange({ ...config, recurrenceEndFixedDate: undefined, recurrenceEndOccurrences: 1 });
      }
    },
    [config, onChange],
  );
  // #endregion

  // #region Notice
  const [isNoticeNone, setIsNoticeNone] = useState(!config.noticeAmount);
  const [isNoticeBefore, setIsNoticeBefore] = useState(config.noticeAmount > 0);
  const selectedNoticeOption = useMemo(
    () => ({
      id: (config.noticeInterval ?? DateInterval.DAY).toString(),
      value: config.noticeInterval ?? DateInterval.DAY,
      text: t(DateIntervalKeys[config.noticeInterval ?? DateInterval.DAY]),
    }),
    [config.noticeInterval, t],
  );
  const onIsNoticeNoneChange = useCallback(
    (value: boolean) => {
      if (value) {
        setIsNoticeNone(value);
        setIsNoticeBefore(!value);
        onChange({ ...config, noticeAmount: 0, noticeInterval: DateInterval.DAY });
      }
    },
    [config, onChange],
  );
  const onIsNoticeBeforeChange = useCallback(
    (value: boolean) => {
      if (value) {
        setIsNoticeBefore(value);
        setIsNoticeNone(!value);
        onChange({ ...config, noticeAmount: 1, noticeInterval: DateInterval.DAY });
      }
    },
    [config, onChange],
  );
  // #endregion

  const datePickerContent = useCallback(
    (value: Date | string | null | undefined, formatfunction: (date: Date, locale?: string) => void) => {
      return !value ? <>{t('periodic-review:empty-date')}</> : <>{formatfunction(new Date(value), i18next.language)}</>;
    },
    [t],
  );

  return (
    <div className="flex flex-col gap-4">
      {/* INITIAL DUE DATE */}
      <div>
        <div className="pb-2">
          <Tooltip text={t('periodic-review:due-date.description')}>
            {(tooltip) => (
              <div className="flex items-center gap-2">
                {t('periodic-review:due-date.title')}
                <div {...tooltip}>
                  <InfoIcon className="h-6 w-6" />
                </div>
              </div>
            )}
          </Tooltip>
        </div>
        <div className="flex">
          <div className="flex-[1]">
            <RadioButton value={isFixedDueDate} label={t('periodic-review:due-date.on')} onChange={onIsFixedDueDateChange} />
          </div>
          <div className="w-[200px]">
            <DatePicker
              disabled={!isFixedDueDate}
              date={config.initialReviewDate ? new Date(config.initialReviewDate) : null}
              onChange={(date) => onChange({ ...config, initialReviewDate: date })}
              type={DatePickerType.BUTTON}
              buttonType={ButtonType.TERTIARY}
              buttonIcon={<CalendarIcon className="h-4 w-4" />}
              buttonContent={datePickerContent(config.initialReviewDate, DateUtils.formatDayLongMonth)}
            />
          </div>
        </div>
        <div className="flex">
          <div className="flex-[1]">
            <RadioButton value={isAfterCompletion} label={t('periodic-review:due-date.after-completion')} onChange={onIsAfterCompletionChange} />
          </div>
          <div className="flex w-[200px] justify-start gap-4 px-2">
            <div className="w-14 flex-shrink-0">
              <Input
                disabled={!isAfterCompletion}
                type="number"
                className="text-center"
                value={`${config.initialReviewAmount}`}
                min={0}
                onChange={(event) => {
                  onChange({ ...config, initialReviewAmount: parseInt(event.target.value) });
                }}
              />
            </div>
            <div className="flex-grow">
              <DropdownSelect
                disabled={!isAfterCompletion}
                className="w-40 text-left"
                value={selectedinItialReviewIntervalOption}
                options={intervals}
                onChange={(option) => {
                  onChange({ ...config, initialReviewInterval: option.value as DateInterval });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* FREQUENCY */}
      <div>
        <div className="pb-2">
          <Tooltip text={t('periodic-review:frequency.description')}>
            {(tooltip) => (
              <div className="flex items-center gap-2">
                {t('periodic-review:frequency.title')}
                <div {...tooltip}>
                  <InfoIcon className="h-6 w-6" />
                </div>
              </div>
            )}
          </Tooltip>
        </div>
        <div>
          <RadioButton value={isRecurringOnce} label={t('periodic-review:frequency.once')} onChange={onIsRecurringOnceChange} />
        </div>
        <div className="flex">
          <div className="flex-[1]">
            <RadioButton value={isRecurringMultiple} label={t('periodic-review:frequency.multiple')} onChange={onIsRecurringMultipleChange} />
          </div>
          <div className="flex w-[200px] justify-start gap-4 px-2">
            <div className="w-14 flex-shrink-0">
              <Input
                disabled={!isRecurringMultiple}
                type="number"
                className="text-center"
                value={`${config.recurrenceFrequencyAmount}`}
                min={0}
                onChange={onRecurrenceFrequencyAmountChange}
              />
            </div>
            <div className="flex-grow">
              <DropdownSelect
                disabled={!isRecurringMultiple}
                className="w-40 text-left"
                value={selectedMultipleRecurrenceOption}
                options={intervals}
                onChange={(option) => {
                  onChange({ ...config, recurrenceFrequencyInterval: option.value as DateInterval });
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* RECURRENCE END */}
      {isRecurringMultiple && config.recurrenceFrequencyAmount > 0 && (
        <div>
          <div className="pb-2">
            <Tooltip text={t('periodic-review:recurrence-end.description')}>
              {(tooltip) => (
                <div className="flex items-center gap-2">
                  {t('periodic-review:recurrence-end.title')}
                  <div {...tooltip}>
                    <InfoIcon className="h-6 w-6" />
                  </div>
                </div>
              )}
            </Tooltip>
          </div>
          <div>
            <RadioButton value={isRecurringEndNever} label={t('periodic-review:recurrence-end.never')} onChange={onIsRecurringEndNeverChange} />
          </div>
          <div className="flex">
            <div className="flex-[1]">
              <RadioButton value={isRecurringEndOn} label={t('periodic-review:recurrence-end.on')} onChange={onIsRecurringEndOnChange} />
            </div>
            <div className="w-[200px]">
              <DatePicker
                disabled={!isRecurringEndOn}
                date={config.recurrenceEndFixedDate ? new Date(config.recurrenceEndFixedDate) : null}
                onChange={(date) => onChange({ ...config, recurrenceEndFixedDate: date })}
                type={DatePickerType.BUTTON}
                buttonType={ButtonType.TERTIARY}
                buttonIcon={<CalendarIcon className="h-4 w-4" />}
                buttonContent={datePickerContent(config.recurrenceEndFixedDate, DateUtils.formatDate)}
                notBefore={config.initialReviewDate ? new Date(config.initialReviewDate) : undefined}
              />
            </div>
          </div>
          <div className="flex">
            <div className="flex-[1]">
              <RadioButton value={isRecurringEndAfter} label={t('periodic-review:recurrence-end.after')} onChange={onIsRecurringEndAfterChange} />
            </div>
            <div className="flex w-[200px] items-center justify-start gap-4 px-2">
              <div className="w-14 flex-shrink-0">
                <Input
                  disabled={!isRecurringEndAfter}
                  type="number"
                  className="text-center"
                  value={`${config.recurrenceEndOccurrences}`}
                  min={0}
                  onChange={(event) => {
                    onChange({ ...config, recurrenceEndOccurrences: parseInt(event.target.value) });
                  }}
                />
              </div>
              <div className="text-right">{t('periodic-review:recurrence-end.occurences')}</div>
            </div>
          </div>
        </div>
      )}
      {/* NOTICE */}
      <div>
        <div className="pb-2">
          <Tooltip text={t('periodic-review:notice.description')}>
            {(tooltip) => (
              <div className="flex items-center gap-2">
                {t('periodic-review:notice.title')}
                <div {...tooltip}>
                  <InfoIcon className="h-6 w-6" />
                </div>
              </div>
            )}
          </Tooltip>
        </div>
        <div>
          <RadioButton value={isNoticeNone} label={t('periodic-review:notice.none')} onChange={onIsNoticeNoneChange} />
        </div>
        <div className="flex">
          <div className="flex-[1]">
            <RadioButton value={isNoticeBefore} label={t('periodic-review:notice.prior')} onChange={onIsNoticeBeforeChange} />
          </div>
          <div className="flex w-[200px] justify-start gap-4 px-2">
            <div className="w-14 flex-shrink-0">
              <Input
                disabled={!isNoticeBefore}
                type="number"
                className="text-center"
                value={`${config.noticeAmount}`}
                min={0}
                onChange={(event) => {
                  onChange({ ...config, noticeAmount: parseInt(event.target.value) });
                }}
              />
            </div>
            <div className="flex-grow">
              <DropdownSelect
                disabled={!isNoticeBefore}
                className="w-40 text-left"
                value={selectedNoticeOption}
                options={intervals}
                onChange={(option) => {
                  onChange({ ...config, noticeInterval: option.value as DateInterval });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewConfig;
