import { FC, useCallback, useMemo, useState } from 'react';
import { PeriodicReviewContext } from '../../../contexts/PeriodicReviewContext';
import { PeriodicReviewConfig } from '../../../models/Form';
import { DateInterval } from '../../../models/DateInterval';
import ReviewGoalStep from './ReviewGoalStep';
import ReviewConfigStep from './ReviewConfigStep';
import ReviewCheckListStep from './ReviewCheckListStep';
import { useTranslation } from 'react-i18next';

export const reviewSteps = ['goal', 'config', 'checklist'] as const;

export type ReviewStep = (typeof reviewSteps)[number];

export type PeriodicReviewMode = 'set' | 'manage' | 'override';

export const EmptyPeriodicReviewConfig: PeriodicReviewConfig = {
  initialReviewAmount: 0,
  initialReviewInterval: DateInterval.MONTH,
  noticeAmount: 0,
  noticeInterval: DateInterval.DAY,
  recurrenceEndOccurrences: 0,
  recurrenceFrequencyAmount: 0,
  recurrenceFrequencyInterval: DateInterval.MONTH,
};

type Props = {
  onCancel: () => void;
  config: PeriodicReviewConfig;
  onSave: (periodicReviewConfig: PeriodicReviewConfig | null) => void;
  onDelete?: () => void;
  state: PeriodicReviewMode;
};

const ReviewWizard: FC<Props> = (props) => {
  const { onCancel, config, onSave, state, onDelete } = props;
  const { t } = useTranslation('periodic-review');

  const wizardTitle = useMemo(() => {
    switch (state) {
      case 'set':
        return t('wizard.set-title');
      case 'manage':
        return t('wizard.manage-title');
      case 'override':
        return t('wizard.override-title');
    }
  }, [state, t]);

  const [configInternal, setConfig] = useState<PeriodicReviewConfig>(config);

  const steps = useMemo<Record<ReviewStep, JSX.Element>>(() => {
    return {
      goal: <ReviewGoalStep />,
      config: <ReviewConfigStep />,
      checklist: <ReviewCheckListStep />,
    };
  }, []);

  const [stepStack, setStepStack] = useState([steps.goal]);
  const nextStep = useCallback(
    (name: ReviewStep) => {
      setStepStack((prev) => [...prev, steps[name]]);
    },
    [steps],
  );

  const prevStep = useCallback(() => {
    setStepStack((prev) => prev.filter((_, i) => i !== prev.length - 1));
  }, []);

  const contextValues = useMemo(
    () => ({
      config: configInternal,
      setConfig,
      nextStep,
      prevStep,
      cancel: onCancel,
      save: onSave,
      remove: onDelete,
      stepNames: reviewSteps,
      state,
      wizardTitle,
    }),
    [configInternal, nextStep, onCancel, onDelete, onSave, prevStep, state, wizardTitle],
  );

  return <PeriodicReviewContext.Provider value={contextValues}>{stepStack[stepStack.length - 1]}</PeriodicReviewContext.Provider>;
};

export default ReviewWizard;
